import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BASE_URL } from '../../utils/endPoint/baseUrl';

export default function ContactList({ onContactChange, onScheduleChange, onSendNowChange }) {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/v1/contacts`, {
      method: "GET",
      headers: { 'Content-Type': 'application/json' },
      credentials: "include"
    })
      .then(res1 => res1.json())
      .then(res2 => {
        if (res2.arr) {
          setContacts(res2.arr);
        }
      })
      .catch(err => { console.log("Error in jsx file: ", err) });
  }, []);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const [isSchedule, setIsSchedule] = useState(false);
  const [isSendNow, setIsSendNow] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleContact = (contact) => {
    const updatedContacts = selectedContacts.includes(contact)
      ? selectedContacts.filter(c => c !== contact)
      : [...selectedContacts, contact];

    setSelectedContacts(updatedContacts);
    onContactChange(updatedContacts);
  };

  const handleScheduleChange = () => {
    setIsSchedule(!isSchedule);
    setIsSendNow(false);
    onScheduleChange(!isSchedule, scheduledDate);
    onSendNowChange(false);
  };

  const handleSendNowChange = () => {
    setIsSendNow(!isSendNow);
    setIsSchedule(false);
    onSendNowChange(!isSendNow);
    onScheduleChange(false, new Date());
  };

  const totalPages = Math.ceil(filteredContacts.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentContacts = filteredContacts.slice(startIndex, startIndex + rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="max-w-md p-4 bg-white rounded-lg shadow mb-4">
      <h2 className="text-lg font-semibold mb-4">Who do you want to send it to?</h2>
      <p className="text-sm text-gray-600 mb-2">Select contacts below</p>

      <div className="mb-4">
        <div className="flex font-semibold bg-gray-100 p-2 rounded-t">
          <div className="w-1/2">Name</div>
          <div className="w-1/2">Phone</div>
        </div>
        {currentContacts.map((contact, index) => (
          <div key={index} className="flex items-center border-b p-2">
            <input
              type="checkbox"
              className="mr-2"
              checked={selectedContacts.includes(contact)}
              onChange={() => toggleContact(contact)}
            />
            <div className="w-1/2">{contact.name}</div>
            <div className="w-1/2">{contact.contactNo}</div>
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center mb-4">
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="text-gray-600">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-gray-300 rounded disabled:opacity-50"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <div className="flex space-x-4 mb-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isSchedule}
            onChange={handleScheduleChange}
            className="mr-2"
          />
          Schedule
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={isSendNow}
            onChange={handleSendNowChange}
            className="mr-2"
          />
          Send Now
        </label>
      </div>

      {isSchedule && (
        <div className="mb-4">
          <label className="block mb-2">Scheduled Date:</label>
          <DatePicker
            selected={scheduledDate}
            showTimeSelect
            onChange={(date) => {
              setScheduledDate(date);
              onScheduleChange(isSchedule, date);
            }}
            className="p-2 border rounded ml-16"
          />
        </div>
      )}
    </div>
  );
}

import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar"
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { MoreVertical, Search, Send, Smile } from "lucide-react"
import { useState, useEffect, useRef } from "react";
import {io} from "socket.io-client";
import "./inbox.css";
import cross from "../assests/cross.svg";
import { BASE_URL } from "../utils/endPoint/baseUrl";

function Popup(props){
	return (
		<div className="popup">
			<div className="main">
				<img src={cross} alt="cross" onClick={() =>props.setFlag(false)}/>
				<p>You have hit your <span>Free 500 Conversations!!</span></p>
				<p>View Plans</p>
			</div>
		</div>
	);
}

export default function Component() {
	//To check if the contact is subscribed or not
	const [isSubscribed, setSubscribed] = useState(true);

	const [flag, setFlag] = useState(false);
	const chatRef = useRef(null);

	const [chats, setChats] = useState([
		// { name: "John Doe", lastMessage: "Hey, how are you?", time: "10:30 AM", unread: 2 },
		// { name: "Jane Smith", lastMessage: "Let's meet tomorrow", time: "Yesterday", unread: 0 },
		// { name: "Bob Johnson", lastMessage: "Thanks for the help!", time: "Tuesday", unread: 1 },
		// { name: "Alice Brown", lastMessage: "Can you send me the file?", time: "Monday", unread: 0 }
	]);

	const [mainChat, setMainChat] = useState({
		avatar:"NS",
		header: "None Selceted",
		recieverEmail:"",
		recieverNumber:null
	});

	const [msgs, setMsgs] = useState([]);

	const [text, setText] = useState("");

	//Automatic scroll down in chats
	useEffect(() =>{
		const scrollDown = () =>{
			if (chatRef.current){
				chatRef.current.scrollTop = chatRef.current.scrollHeight;
			}
		}
		scrollDown();
	}, [msgs]);

	useEffect(() =>{
		const url = BASE_URL.replace("/api", "");
		const newSocket = io(`${url}`, {transports: ["websocket"]});
		
		newSocket.on("connect", () =>{
			console.log("Connected to " + newSocket.id);
		});
		const fetchFunc = async () =>{
			await fetch(`${BASE_URL}/v1/getConvos`, {
				method:"GET",
				headers: {'Content-Type':'application/json'},
				credentials: "include"
			})
			.then(res1 =>res1.json())
			.then(res2 => {
				if (res2.success===true){
					setChats(res2.arr);
				}
				else{
					console.log("Error in controller: "+ res2.error);
				}
			})
			.catch(err => console.log(err));
		};

		fetchFunc();

		newSocket.on("newMessage", data=>{
			if (data.contactNo===mainChat.recieverNumber){
				setMsgs(data.arr);
			}
			fetchFunc()
		});
		newSocket.on("readingMsgs", () =>{fetchFunc()});

		return () =>{
			newSocket.off("newMessage");
			newSocket.off("readingMsgs");
			newSocket.disconnect();
		}
	}, [mainChat]);

	const getMessages = async (recieverNumber, msgIdArr) =>{
		await fetch(`${BASE_URL}/v1/getMessages`, {
			method:"POST",
			headers: {
				'Content-Type':'application/json'
			},
			credentials: "include",
			body: JSON.stringify({reciever:recieverNumber, msgs:msgIdArr}),
		})
		.then(res1 =>res1.json())
		.then(res2 =>{
			if (res2.arr){
				setMsgs(res2.arr);
				setSubscribed(res2.isSubscribed);
			}
			else{
				console.log(res2.error);
				setMsgs([]);
			}
		})
		.catch(err =>console.log(err));
	};

  	const renderChatList = chats.map((x, ind) =>{
		return (
			<div key={ind} className="flex items-center p-4 hover:bg-gray-100 cursor-pointer" 
			onClick={async () =>{setMainChat({
				avatar:x.name[0], header: x.name, recieverEmail:x.businessEmail, recieverNumber:x.contactNo}); 
				await getMessages(x.contactNo, x.messagesIdArray); 
			}}>
				<Avatar className="mr-4">
					<AvatarImage src={`/placeholder-avatar-${x.id}.jpg`} alt={x.name} />
					<AvatarFallback>{x.name[0]}</AvatarFallback>
				</Avatar>
				<div className="flex-1">
					<div className="flex justify-between items-baseline">
						<h3 className="font-semibold">{x.name}</h3>
						<span className="text-xs text-gray-500">{x.time}</span>
					</div>
					<p className="text-sm text-gray-600 truncate">{x.lastMessage?(x.lastMessage):("No messages sent")}</p>
				</div>
				{x.unread > 0 && (
					<div className="bg-green-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
						{x.unread}
					</div>
				)}
			</div>
		);
  	});

	const sendMessage = async () =>{

		await fetch(`${BASE_URL}/v1/sendMessage`, {
			method:"POST",
			headers: {'Content-Type':'application/json'},
			body:JSON.stringify({
				from:localStorage.getItem("email"),
				to:mainChat.recieverNumber,
				content:text
			})
		})
		.then(res1 =>res1.json())
		.then(res2 =>{
			if (res2.convo_limit_reached){
				setFlag(true);
			}
			else if (res2.arrayOfMsgs){
				setText("");
				setMsgs(res2.arrayOfMsgs);
			}
			else{
				console.log(res2);
			}
		})
		.catch(err =>console.log(err));
	}

	const renderMsgs = msgs.map((x, ind) =>{
		if (x.from===localStorage.getItem("email")){
			return (
				<div className="flex justify-end" key={ind}>
					<div className="bg-green-500 text-white rounded-lg p-3 max-w-xs">
						{x.content}
					</div>
				</div>
			);
		}
		else{
			return (
				<div className="flex justify-start" key={ind}>
					<div className="bg-white rounded-lg p-3 max-w-xs">
						{x.content}
					</div>
				</div>
			);
		}
	});

  	return (
		<>{flag && (<Popup setFlag={setFlag}/>)}
    <div className="flex h-screen bg-gray-100" style={{fontFamily:"poppins-reg"}}>
      {/* Sidebar */}
      <div className="w-1/3 bg-white border-r border-gray-300">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-300">
          <Avatar>
            <AvatarImage src="/placeholder-avatar.jpg" alt="User" />
            <AvatarFallback>U</AvatarFallback>
          </Avatar>
          <div className="flex space-x-2">
            <Button variant="ghost" size="icon">
              <MoreVertical className="w-5 h-5" />
            </Button>
          </div>
        </div>
        {/* Search */}
        <div className="p-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input className="pl-10" placeholder="Search or start new chat" />
          </div>
        </div>
        {/* Chat list */}
        <div className="overflow-y-auto h-[calc(100vh-140px)]">
			{renderChatList}
        </div>
      </div>
      {/* Main chat area */}
      <div className="flex-1 flex flex-col">
        {/* Chat header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-gray-100">
          <div className="flex items-center">
            <Avatar className="mr-4">
              <AvatarImage src="/placeholder-avatar-2.jpg" alt="Jane Smith" />
              <AvatarFallback>{mainChat.avatar}</AvatarFallback>
            </Avatar>
            <h2 className="font-semibold">{mainChat.header}</h2>
          </div>
          <Button variant="ghost" size="icon">
            <MoreVertical className="w-5 h-5" />
          </Button>
        </div>
        {/* Chat messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4" ref={chatRef} style={{scrollBehavior:"smooth"}}>
			{renderMsgs}
          <div className="flex justify-end">
            {/* <div className="bg-green-500 text-white rounded-lg p-3 max-w-xs">
              Hey Jane, how are you doing?
            </div> */}
          </div>
          <div className="flex justify-start">
            {/* <div className="bg-white rounded-lg p-3 max-w-xs">
              Hi! I'm doing great, thanks for asking. How about you?
            </div> */}
          </div>
        </div>
        {/* Message input */}
        <div className="p-4 bg-gray-100">
			{mainChat.recieverNumber!==null && isSubscribed && (
				<div className="flex items-center space-x-2">
					<Button variant="ghost" size="icon">
						<Smile className="w-5 h-5" />
					</Button>
					
					<Input 
						className="flex-1" 
						placeholder="Type a message" 
						value={text}
						onChange={(e) =>setText(e.target.value)}
					/>
					
					<Button size="icon" onClick={sendMessage}>
						<Send className="w-5 h-5" />
					</Button>
			  	</div>
			)}
        </div>
      </div>
    </div></>
  )
}

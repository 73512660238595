import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactList from "./ContactList";
import PhoneImage from "../../assests/Phone — FixedHeight.png";
import { BASE_URL } from "../../utils/endPoint/baseUrl";
import { getMetaStorageValues } from '../../context/getAccessTokenData';

export const Modal = ({ closeModal, onSubmit, defaultValue }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [variableValues, setVariableValues] = useState({});
  const [errors, setErrors] = useState("");

  const { accessToken, whatsappId, phoneId } = getMetaStorageValues();

  const [formState, setFormState] = useState(
    defaultValue || {
      template: "",
      title: "",
      selectedContacts: [],
      isSchedule: false,
      scheduledDate: new Date(),
      isSendNow: false,
    }
  );

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v20.0/${whatsappId}/message_templates?status=approved`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setTemplates(response.data.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, [whatsappId, accessToken]);

  const handleChange = (e) => {
    if (e.target.name === "title") {
      const template = templates.find((t) => t.name === e.target.value);
      setSelectedTemplate(template);
      
      // Reset variable values when template changes
      const bodyComponent = template?.components?.find(c => c.type === "BODY");
      const variables = bodyComponent?.text.match(/{{[^}]+}}/g) || [];
      const initialVariables = {};
      variables.forEach((_, index) => {
        initialVariables[index] = "";
      });
      setVariableValues(initialVariables);
    }
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleVariableChange = (index, value) => {
    setVariableValues({ ...variableValues, [index]: value });
  };

  const createMessageBody = (contact, templateName) => {
    const bodyComponent = selectedTemplate?.components?.find(c => c.type === "BODY");
    const hasVariables = bodyComponent?.text.match(/{{[^}]+}}/g)?.length > 0;

    const messageBody = {
      messaging_product: "whatsapp",
      to: contact.contactNo,
      type: "template",
      template: {
        name: templateName,
        language: {
          code: "en"
        },
        components: []
      }
    };

    // Only add body component with parameters if template has variables
    if (hasVariables) {
      messageBody.template.components.push({
        type: "body",
        parameters: Object.values(variableValues).map(value => ({
          type: "text",
          text: value
        }))
      });
    }

    return messageBody;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      for (const contact of formState.selectedContacts) {
        const messageBody = createMessageBody(contact, formState.title);

        const whatsappResponse = await axios.post(
          `https://graph.facebook.com/v20.0/${phoneId}/messages`,
          messageBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        
        console.log(`✅ Message sent successfully to ${contact.contactNo}`);
        console.log('WhatsApp Response:', whatsappResponse.data);
    
      }

      const response = await axios.post(
        `${BASE_URL}/broadcasts`, 
        formState, 
        {withCredentials: true}
      );

      if (response.status === 201) {
        closeModal();
        onSubmit(formState);
      }
    } catch (error) {
      console.error("Error sending messages:", error);
      setErrors("An error occurred while sending the messages.");
    }
  };

  const validateForm = () => {
    if (!formState.template || !formState.title || formState.selectedContacts.length === 0) {
      setErrors("Please fill in all required fields and select at least one contact.");
      return false;
    }

    const bodyComponent = selectedTemplate?.components?.find(c => c.type === "BODY");
    const variables = bodyComponent?.text.match(/{{[^}]+}}/g) || [];
    
    if (variables.length > 0) {
      const allVariablesFilled = Object.values(variableValues).every(value => value.trim() !== "");
      if (!allVariablesFilled) {
        setErrors("Please fill in all variable values.");
        return false;
      }
    }

    setErrors("");
    return true;
  };

  return (
    <div className="modal-container" onClick={(e) => {
      if (e.target.className === "modal-container") closeModal();
    }}>
      <div className="h-[90vh] w-[60vw] relative inset-0 -z-10 items-center px-5 pt-4 pb-12 left-36 bg-gray-100 rounded-xl overflow-hidden py-4">
        <div className="w-full h-12 text-lg font-semibold flex items-center px-[2%] border-gray-500 border-b-2">
          <h2>Create New Broadcast</h2>
        </div>

        <div className="w-full h-full rounded-xl overflow-hidden flex justify-center items-center mt-4">
          <div className="w-[70%] h-[100%] rounded-xl overflow-y-scroll">
            <div className="h-[60vh] w-[80%]">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="template">Broadcast Name</label>
                  <input
                    className="w-[50%]"
                    name="template"
                    onChange={handleChange}
                    value={formState.template}
                    required
                  />
                </div>

                <div className="form-group flex justify-center gap-2">
                  <label htmlFor="title">Select Template Message</label>
                  <select
                    name="title"
                    value={formState.title}
                    className="w-[50%]"
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a template</option>
                    {templates.map((template, index) => (
                      <option key={index} value={template.name}>
                        {template.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedTemplate && (
                  <div className="form-group">
                    {selectedTemplate.components
                      .filter(c => c.type === "BODY")
                      .map(component => {
                        const variables = component.text.match(/{{[^}]+}}/g) || [];
                        if (variables.length === 0) return null;

                        return (
                          <div key={component.type}>
                            <h3 className="mb-4">Add Variable Values</h3>
                            {variables.map((variable, index) => (
                              <div key={index} className="mb-2">
                                <label htmlFor={`variable-${index}`} className="mr-4">
                                  {variable}
                                </label>
                                <input
                                  id={`variable-${index}`}
                                  className="w-[50%]"
                                  onChange={(e) => handleVariableChange(index, e.target.value)}
                                  value={variableValues[index] || ""}
                                  required
                                />
                              </div>
                            ))}
                          </div>
                        );
                    })}
                  </div>
                )}

                <ContactList
                  onContactChange={(selectedContacts) => setFormState({ ...formState, selectedContacts })}
                  onScheduleChange={(isSchedule, scheduledDate) => setFormState({ ...formState, isSchedule, scheduledDate })}
                  onSendNowChange={(isSendNow) => setFormState({ ...formState, isSendNow, scheduledDate: new Date() })}
                  selectedContacts={formState.selectedContacts}
                />

                {errors && (
                  <div className="error text-red-500 mb-2">{errors}</div>
                )}

                <button type="submit" className="btn bg-green-500 px-5 py-3 rounded-lg mb-10">
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="w-[30%] h-full">
            <img src={PhoneImage} alt="phone" className="relative mx-auto h-[95%] w-full z-10" />
          </div>
        </div>
      </div>
    </div>
  );
};
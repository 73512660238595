"use client";
import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Topbar from "../Contact Page/components/topbar/Topbar";
import { useMutation } from "@tanstack/react-query";
import {
  updateMetaAccessTokenAPI,
  updateMetaCredentialsAPI,
} from "../APIServices/usersAPI";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const accessTokenMutation = useMutation({
    mutationFn: updateMetaAccessTokenAPI,

    onSuccess: (response) => {
      console.log("Access Token updated successfully");
    },
    onError: (error) => {
      console.error("Access Token update error:", error);
    },
  });

  const credentialsMutation = useMutation({
    mutationFn: updateMetaCredentialsAPI,

    onSuccess: (response) => {
      console.log("Credentials updated successfully");
    },
    onError: (error) => {
      console.error("Credentials update error:", error);
    },
  });

  const sessionInfoListener = (event) => {
    if (
      event.origin !== "https://www.facebook.com" &&
      event.origin !== "https://web.facebook.com"
    ) {
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.type === "WA_EMBEDDED_SIGNUP") {
        if (data.event === "FINISH") {
          console.log("hello");
          console.log(data);
          const { phone_number_id, waba_id } = data.data;

          updateMetaCredentialsAPI.mutate({ phone_number_id, waba_id });

          console.log(phone_number_id, waba_id);
        } else {
          const { current_step } = data.data;
          console.log(current_step);
        }
      }
    } catch (e) {
      console.log("Non JSON Response", event.data);
    }
  };

  const fetchAccessToken = async (signupCode) => {
    try {
      const { data } = await axios.get(
        `https://graph.facebook.com/v20.0/oauth/access_token?client_id=815620406907245&client_secret=84a8e5b7f376980384ff6810099a2eb5&code=${signupCode}`
      );

      const access_token = data.access_token;

      accessTokenMutation.mutate(access_token);

      console.log(access_token);
    } catch (error) {
      console.log(error);
    }
  };

  function launchWhatsAppSignup() {
    if (window.FB) {
      console.log("yes 1");
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log("yes 2");
            console.log("response is : ", response);
            const code = response.authResponse.code;
            console.log(code);
            fetchAccessToken(code).then((response) => {
              console.log(response);
            });
          } else {
            console.log("response is : ", response);
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: "740873364883065",
          response_type: "code",
          override_default_response_type: true,
          extras: {
            feature: "whatsapp_embedded_signup",
            sessionInfoVersion: 2,
          },
        }
      );
    }
  }

  useEffect(() => {
    // if (localStorage.getItem("accessToken")){
    //   navigate("/home");
    // }

    const loadFacebookSDK = new Promise((resolve) => {
      console.log("yes 3");
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "815620406907245",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v20.0",
        });
        resolve();
      };

      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          console.log("yes 4");
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    });

    loadFacebookSDK.then(() => {
      console.log("Facebook SDK loaded successfully");
    });

    window.addEventListener("message", sessionInfoListener);

    // Cleanup function
    return () => {
      window.removeEventListener("message", sessionInfoListener);
    };
  }, [navigate]);

  return (
    <>
      <Topbar />
      <div className="flex flex-col gap-4 w-full mx-auto py-20 items-center justify-start">
        <h1>Revolutionize your Business through us.</h1>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-4">Create Business</h2>
          <div className="flex justify-between items-end">
            <p className="text-gray-600">
              Create your own business account after sign-up
            </p>
            <button
              onClick={launchWhatsAppSignup}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;

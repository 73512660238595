import React from "react";
import "./Privacy.css";
import logo from "../Landing Page/images/forNavbar/logo.png";
import sasefied from "../Landing Page/images/forNavbar/sasefied.png";

const PrivacyPolicy = () => {
  return (
    <>
      {/* <Navbar /> */}
      <div className="tophead">
        <span style={{flexDirection:"column", margin:"auto auto auto 40px"}}>
          <img src={logo} alt="logo" className="logo"/>
          <p>Reybot</p>
        </span>

        <span style={{flexDirection:"column", margin:"auto 40px auto auto"}}>
          <span className="sas">
            <img src={sasefied} alt="sasefied" />
            <p>Sasefied</p>
          </span>
          <p>Sasefied</p>
        </span>
      </div>
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-title">Privacy Policy</h1>
        <p className="last-updated">Last Updated: 15 Jan, 2025</p>

        <section className="policy-section">
          <h2>Terms & Conditions</h2>
          <h3>1. Introduction</h3>

          <p>
            Welcome to Reybot (the “Platform”), operated by Sasefied Limited
            (“we,” “us,” or “our”). By accessing or using the Platform, you
            agree to be bound by these Terms & Conditions (“Terms”). If you do
            not agree, please refrain from using the Platform.
          </p>
          <h3>2. Services Provided</h3>
          <p>
            Reybot enables users to create WhatsApp bots using the Meta WhatsApp
            Business APIs and AI tools. Our services are available on a
            subscription basis.
          </p>

          <h3>3. User Eligibility</h3>

          <ul>
            <li>Users must be at least 18 years old.</li>
            <li>
              You must comply with all applicable laws and regulations related
              to your use of the Platform and Meta’s WhatsApp Business APIs.
            </li>
          </ul>

          <h3>4. Subscriptions and Payments</h3>

          <ul>
            <li>
              Our services are subscription-based, with details and pricing
              outlined on our website.
            </li>
            <li>
              Payments are due in advance and are non-refundable unless required
              by law.
            </li>
            <li>
              Subscriptions will renew automatically unless canceled per the
              terms specified on the Platform.
            </li>
          </ul>

          <h3>5. User Responsibilities</h3>

          <ul>
            <li>
              You are responsible for the content and configuration of your
              bots.
            </li>
            <li>
              You must not use Reybot to create bots that distribute harmful,
              misleading, illegal, or inappropriate content.
            </li>
          </ul>

          <h3>6. Integration with Meta APIs </h3>
          <p>
            Reybot integrates with Meta’s WhatsApp Business APIs. By using
            Reybot, you agree to comply with Meta’s Platform Terms and Policies
            available at{" "}
            <a href="https://developers.facebook.com/terms/dfc_platform_terms/">
              Meta Developer Platform Terms.
            </a>
          </p>

          <h3>7. Intellectual Property</h3>
          <p>
            All content, software, and trademarks on the Platform are owned by
            or licensed to Sasefied Limited. Unauthorized use is prohibited.
          </p>
          <h3>8. Limitation of Liability</h3>
          <p>
            We provide the Platform "as is" without warranties of any kind. We
            are not responsible for any interruptions, loss of data, or damages
            resulting from your use of the Platform.
          </p>
          <h3>9. Termination</h3>
          <p>
            We reserve the right to suspend or terminate access to the Platform
            if these Terms are violated.
          </p>
          <h3>10. Governing Law</h3>
          <p>
            These Terms are governed by the laws of England. Disputes will be
            resolved in English courts.
          </p>
        </section>
        <section className="policy-section">
          <h2 className="policy-section-title">Privacy Policy</h2>
          <h3>1. Introduction</h3>
          <p>
            This Privacy Policy explains how Sasefied Limited collects, uses,
            and protects your data when you use Reybot. By using the Platform,
            you consent to the practices described in this policy.
          </p>

          <h3>2. Information We Collect</h3>

          <ul>
            <li>Personal Data: Name, email, billing details.</li>
            <li>Usage Data: Configuration and logs of bot interactions.</li>
            <li>
              Meta API Data: Data accessed or used through the Meta WhatsApp
              Business APIs, subject to Meta’s policies.
            </li>
          </ul>

          <h3>3. How We Use Your Information</h3>
          <ul>
            <li>To provide and manage your subscription.</li>
            <li>
              To integrate and manage bots through the Meta WhatsApp Business
              APIs.
            </li>
            <li>To improve the Platform and troubleshoot issues.</li>
          </ul>

          <h3>4. Data Sharing</h3>
          <p>We may share your data:</p>
          <ul>
            <li>With Meta, as required for WhatsApp bot integration.</li>
            <li>With service providers for payment processing or analytics.</li>
            <li>As required by law.</li>
          </ul>

          <h3>5. Reference to Meta’s Privacy Policies</h3>

          <p>
            By using Reybot, you acknowledge that your use of the Meta WhatsApp
            Business APIs is subject to Meta’s Developer Platform Terms and
            Policies{" "}
            <a href="https://developers.facebook.com/terms/dfc_platform_terms/">
              Developer Platform Terms and Policies.
            </a>
          </p>

          <h3>6. Data Retention</h3>

          <p>
            We retain your data for as long as necessary to provide our services
            or as required by law.
          </p>

          <h3>7. Your Rights</h3>
          <ul>
            <li>Access, correct, or delete your data.</li>
            <li>Withdraw consent for non-essential data processing.</li>
          </ul>

          <h3>8. Security</h3>
          <p>
            We implement robust measures to protect your data but cannot
            guarantee absolute security.
          </p>

          <h3>9. Updates to This Policy</h3>
          <p>
            We may update this Privacy Policy and will notify you of significant
            changes.
          </p>
        </section>
        <section className="policy-section">
          <h2>Cookies Policy</h2>
          <h3>1. Introduction</h3>
          <p>
            This Cookies Policy explains how we use cookies and similar
            technologies on the Reybot website.
          </p>

          <h3>2. What Are Cookies?</h3>
          <p>
            Cookies are small text files stored on your device to enhance your
            browsing experience.
          </p>

          <h3>3. Types of Cookies We Use</h3>

          <ul>
            <li>
              Essential Cookies: Necessary for the functionality of the website.
            </li>
            <li>
              Analytics Cookies: To monitor usage and improve the Platform.
            </li>
            <li>
              Third-Party Cookies: For integrations such as Meta services.
            </li>
          </ul>

          <h3>4. Managing Cookies</h3>
          <p>
            You can control or disable cookies through your browser settings.
            Disabling cookies may limit your access to certain features.
          </p>

          <h3>5. Third-Party Cookies and Meta</h3>
          <p>
            Our use of Meta APIs involves third-party cookies. Your interaction
            with Meta services is subject to Meta’s{" "}
            <a href="https://developers.facebook.com/terms/dfc_platform_terms/  ">
              Privacy Policy
            </a>
            .
          </p>
        </section>

        <section className="policy-section">
          <h2>GDPR Privacy</h2>
          <h3>Legal Basis for Processing Personal Data under GDPR</h3>
          <p>We may process Personal Data under the following conditions:</p>
          <ul>
            <li>
              Consent: You have given Your consent for processing Personal Data
              for one or more specific purposes. Performance of a contract:
              Provision of Personal Data is necessary for the performance of an
              agreement with You and/or for any pre-contractual obligations
              thereof.
            </li>
            <li>
              Legal obligations: Processing Personal Data is necessary for
              compliance with a legal obligation to which the Company is
              subject.
            </li>
            <li>
              Vital interests: Processing Personal Data is necessary in order to
              protect Your vital interests or of another natural person.
            </li>
            <li>
              Public interests: Processing Personal Data is related to a task
              that is carried out in the public interest or in the exercise of
              official authority vested in the Company.
            </li>
            <li>
              Legitimate interests: Processing Personal Data is necessary for
              the purposes of the legitimate interests pursued by the Company.
            </li>
          </ul>

          <p>
            In any case, the Company will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>

          <h3>Your Rights under the GDPR</h3>
          <p>
            The Company undertakes to respect the confidentiality of Your
            Personal Data and to guarantee You can exercise Your rights.
          </p>
          <p>
            You have the right under this Privacy Policy, and by law if You are
            within the EU, to:
          </p>
          <ul>
            <li>
              Request access to Your Personal Data. The right to access, update
              or delete the information We have on You. Whenever made possible,
              you can access, update or request deletion of Your Personal Data
              directly within Your account settings section. If you are unable
              to perform these actions yourself, please contact Us to assist
              You. This also enables You to receive a copy of the Personal Data
              We hold about You. Request correction of the Personal Data that We
              hold about You. You have the right to have any incomplete or
              inaccurate information We hold about You corrected.
            </li>
            <li>
              Object to processing of Your Personal Data. This right exists
              where We are relying on a legitimate interest as the legal basis
              for Our processing and there is something about Your particular
              situation, which makes You want to object to our processing of
              Your Personal Data on this ground. You also have the right to
              object where We are processing Your Personal Data for direct
              marketing purposes.
            </li>
            <li>
              Request erasure of Your Personal Data. You have the right to ask
              Us to delete or remove Personal Data when there is no good reason
              for Us to continue processing it.
            </li>
            <li>
              Request the transfer of Your Personal Data. We will provide to
              You, or to a third-party You have chosen, Your Personal Data in a
              structured, commonly used, machine-readable format. Please note
              that this right only applies to automated information which You
              initially provided consent for Us to use or where We used the
              information to perform a contract with You.
            </li>
            <li>
              Withdraw Your consent. You have the right to withdraw Your consent
              on using your Personal Data. If You withdraw Your consent, We may
              not be able to provide You with access to certain specific
              functionalities of the Service.
            </li>
          </ul>
          <h3>Exercising of Your GDPR Data Protection Rights</h3>
          <p>
            You may exercise Your rights of access, rectification, cancellation
            and opposition by contacting Us. Please note that we may ask You to
            verify Your identity before responding to such requests. If You make
            a request, We will try our best to respond to You as soon as
            possible.
          </p>
          <p>
            You have the right to complain to a Data Protection Authority about
            Our collection and use of Your Personal Data. For more information,
            if You are in the European Economic Area (EEA), please contact Your
            local data protection authority in the EEA.
          </p>
          <h3>Children's Privacy</h3>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>

          <h3>Links to Other Websites</h3>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h3>Changes to this Privacy Policy</h3>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </section>

        <section className="policy-section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>

          <ul>
            <li>By email: support@reybot.co.uk</li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import axios from "axios";
import { BASE_URL } from "../utils/endPoint/baseUrl";
export const registerAPI = async (userData) => {
  const response = await axios.post(`${BASE_URL}/v1/users/register`, userData);
  return response?.data;
};

export const verifyOTPAPI = async (otpData) => {
  const response = await axios.post(`${BASE_URL}/v1/users/sendotp`, otpData);
  return response?.data;
};
export const loginAPI = async (userData) => {
  const response = await axios.post(
    `${BASE_URL}/v1/users/login`,
    {
      email: userData?.email,
      password: userData?.password,
    },
    {
      withCredentials: true,
    }
  );
  return response?.data;
};

export const getProfileAPI = async () => {
  console.log("Profile Called");
  try {
    const response = await axios.get(`${BASE_URL}/v1/users/fetch`, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log("Response from Get Profile is", response);
    return response?.data;
  } catch (error) {
    console.log("Error from Get Profile is", error);
  }
};

export const updateProfileAPI = async (profileData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/v1/users/update-profile`,
      profileData,
      {
        withCredentials: true,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const updatePasswordAPI = async (passwordData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/v1/users/update-password`,
      passwordData,
      {
        withCredentials: true,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error updating password:", error);
    throw error;
  }
};

export const updateCompanyAPI = async (companyData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/v1/users/update-company`,
      companyData,
      {
        withCredentials: true,
      }
    );
    return response?.data;
  } catch (error) {
    console.error("Error updating Company:", error);
    throw error;
  }
};
export const logoutAPI = async () => {
  const response = await axios.get(`${BASE_URL}/v1/users/logout`, {
    withCredentials: true,
  });
  return response?.data;
};

export const deleteAccountAPI = async () => {
  const response = await axios.delete(`${BASE_URL}/v1/users/delete-account`, {
    withCredentials: true,
  });
  return response?.data;
};

export const updateMetaAccessTokenAPI = async (access_token) => {
  const response = await axios.put(
    `${BASE_URL}/v1/users/update-access-token`,
    { access_token },
    {
      withCredentials: true,
    }
  );
  return response?.data;
};

export const updateMetaCredentialsAPI = async (metaCredentials) => {
  const response = await axios.put(
    `${BASE_URL}/v1/users/update-meta-credentials`,
    metaCredentials,
    {
      withCredentials: true,
    }
  );
  return response?.data;
};

export const getAccessTokenAPI = async () => {
  const response = await axios.get(
    `${BASE_URL}/v1/users/get-long-lived-access-token`,
    {
      withCredentials: true,
    }
  );
  return response?.data;
};

export const generateLongLivedAccessTokenAPI = async (
  SHORT_LIVED_ACCESS_TOKEN,
  app_id,
  phone_id
) => {
  const response = await axios.post(
    `${BASE_URL}/v1/users/generate-long-lived-access-token`,
    {
      SHORT_LIVED_ACCESS_TOKEN,
      app_id,
      phone_id,
    },
    {
      withCredentials: true,
    }
  );
  return response?.data;
};
